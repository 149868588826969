import Vue from 'vue'
import './plugins/mixins';
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueGtag from 'vue-gtag'
/*
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faHatWizard);
*/

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "G-RL7NZG55ZV" }
});

new Vue({
  vuetify,
  //FontAwesomeIcon,
  render: h => h(App)
}).$mount('#app')
