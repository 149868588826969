import { apiPost } from "./api";

export default class ServicioFacturas {
  
  static Catalogos(){
     return apiPost("/Facturacion/Catalogos","ObtenerCatalogos");
  }

  static CatalogosSucursales(){
    return apiPost("/Facturacion/Catalogos/Sucursales","ObtenerCatalogos");
  }

  static CatalogosCFDI(){
    return apiPost("/Facturacion/Catalogos/CFDI","ObtenerCatalogos");
  }

  static getRFC(datos){
    return apiPost("/Facturacion/Catalogos/RFC", datos);
}

  static generarFactura(datos){
      return apiPost("/Facturacion/CFDI/Crear", datos);
  }

  static validarTicket(datos){
    const data = {
        DSucursalID: datos.DSucursalID,
        Folio: datos.Folio,
        Fecha: datos.Fecha,
        Importe: datos.Importe,
        /*
        FechaInicial: `${dia}T00:00:00`,
        FechaFinal: `${dia}T23:59:59`,
        */
      };
      return apiPost("/Facturacion/ValidarTicket", data);
  }

}
