<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <v-form ref="formDatos" class="ml-10 mt-4 mr-10" v-model="valid" lazy-validation>
    <div>
    
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="blue darken-4"
      ></v-progress-linear>


    <v-select v-if="paso === 1"
      v-model="DSucursalID"
      :rules="[v => !!v || 'Campo requerido']"
      label="Sucursal"
      required
      :items="catalogos.Sucursales"
      item-text="Sucursal"
      item-value="DSucursalID"
    >
    </v-select>  

    <v-text-field v-if="paso === 1"
      v-model="Folio"
      label="Folio"
      required
      :rules="[v => !!v || 'Campo requerido']"
    ></v-text-field>

    <v-menu v-if="paso === 1"
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field  v-if="paso === 1"
          v-model="date"
          label="Fecha"
          v-bind="attrs"
          hint="Formato: YYYY-MM-DD"
          persistent-hint
          v-on="on"
          readonly
        ></v-text-field>
      </template>
      <v-date-picker v-if="paso === 1"
        v-model="date"
        no-title
        @input="menu1 = false"
        :max="maxDate"
        :min="minDate"
        :show-current="true"
      ></v-date-picker>
    </v-menu>  

    <v-text-field v-if="paso === 1"
      v-model="Importe"
      label="Importe"
      type="number"
      prefix="$"
      required
      :rules="[v => !!v || 'Campo requerido']"
    ></v-text-field>

    <v-btn v-if="paso === 1"
      class="mr-4 mt-4"
      @click="validarFactura"
      :disabled="DesactivarBotonPaso1"
      ref="boton"
    >
      Validar
    </v-btn>

    <v-text-field v-if="paso === 2"
      v-model="RFC"
      label="RFC"
      @change="buscarRFC()"
      required
      :rules="[v => !!v || 'Campo requerido']"
    ></v-text-field>

    <v-text-field v-if="paso === 2"
      v-model="RazonSocial"
      label="Razón Social"
      required
      :rules="[v => !!v || 'Campo requerido']"
    ></v-text-field>

    <v-text-field v-if="paso === 2"
      v-model="CodigoPostal"
      label="Código Postal"
      required
      :rules="[v => !!v || 'Campo requerido']"
    ></v-text-field>
    
    <v-select v-if="paso === 2"
      v-model="RegimenFiscalID"
      :rules="[v => !!v || 'Campo requerido']"
      label="Regimen Fiscal"
      required
      :items="catalogos.RegimenFiscal"
      item-text="RegimenFiscal"
      item-value="RegimenFiscalID"
    >
    </v-select>    

    <v-select v-if="paso === 2"
      v-model="UsoCFDIID"
      :rules="[v => !!v || 'Campo requerido']"
      label="Uso del CFDI"
      required
      :items="catalogos.UsoCFDI"
      item-text="UsoCFDI"
      item-value="UsoCFDIID"
    >
    </v-select>

    <v-select v-if="paso === 2"
      v-model="FormaPagoID"
      :rules="[v => !!v || 'Campo requerido']"
      label="Método de Pago"
      required
      :items="catalogos.FormasPago"
      item-text="FormaPago"
      item-value="FormaPagoID"
    >
    </v-select>

    <v-text-field v-if="paso === 2"
      v-model="Email"
      label="Correo Electrónico"
      required
      :rules="[v => !!v || 'Campo requerido']"
    ></v-text-field>

    <v-btn v-if="paso === 2"
      class="mr-4 mt-4"
      @click="generarFactura"
      :disabled="DesactivarBotonPaso2"
      ref="boton"
    >
      Generar Factura
    </v-btn>
  </div>

  
  <!--
  <v-btn v-if="paso === 2"
      class="mr-4 mt-4"
      @click="pasoAnterior"
      :disabled="DesactivarBoton"
      ref="boton2"
    >
      Regresar
    </v-btn>
    -->

    <v-btn v-if="paso === 3"
      class="mr-4 mt-4"
      ref="boton3"
      @click.prevent="descargarArchivo(URLPDF)"
    >
      Descargar PDF
    </v-btn>

    <v-btn v-if="paso === 3"
      class="mr-4 mt-4"
      ref="boton4"
      @click.prevent="descargarArchivo(URLXML)"
    >
      Descargar XML
    </v-btn>


    <div class="mt-8">
      <p class="text-caption font-weight-light"><mark><b>IMPORTANTE:</b></mark> Para generar la factura es necesario contar con la constancia de situación fiscal e ingresar los datos solicitados: <a href="CSF.html" Target="_blank">ejemplo</a> </p>
      <p class="text-caption font-weight-light">En caso de presentar algún problema nos pueden contactar por correo electrónico: <a href="mailto:monitoreo@morro.mx">monitoreo@morro.mx</a></p>
    </div>

  </v-form>
</template>


<script>

  //import config from '../config';
  import ServicioFacturas from "../services/facturas";
  import {saveAs} from 'file-saver';
  import axios from 'axios';

  export default {
    name: 'FacturacionMorro',

    data:  () => ({
      mostrar: true,
      paso: 1,
      valid: true,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      maxDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      minDate: (new Date(new Date().getFullYear(),new Date().getMonth()-1, 1)).toISOString().substr(0, 10),
      menu1: false,   
      DSucursalID : null,
      Folio: null,
      Importe: null,
      RazonSocial: null,
      RFC: null,
      CodigoPostal: null,
      RegimenFiscalID: null,
      UsoCFDIID: null,
      FormaPagoID: null,
      Email: null,
      desactivado: false,
      URLPDF: null,
      URLXML: null,
      catalogos: {
        Sucursales: [],
        RegimenFiscal: [],
        UsoCFDI: [],
        FormasPago: [],
      },
      DesactivarBotonPaso1: false,
      DesactivarBotonPaso2: false,
      loading: false,
    }),

    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
      /*
      getEndDate() {
        var endDate = new Date(this.date.getFullYear(), this.date.getMonth()+1, 10);
        return endDate.toISOString().slice(0,10)
      },
      getStartDate(){
        var minDate = new Date(this.date.getFullYear(), this.date.getMonth()+1, 1);
        return minDate.toISOString().slice(0,10)
      }
      */
    },

   /*
    watch: {
      date () {
        this.dateFormatted = this.formatDate(this.date)
      },
    },
    */
    methods: {
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      validate () {
        this.$refs.form.validate()
      },
      async descargarArchivo(Archivo) {
        //window.open(this.config.RutaFacturasCompleta + Archivo, "_blank");
       
       const url = this.config.RutaFacturasCompleta + Archivo;
       const ext = Archivo.split('.');
       const file = 'morro-factura.' + ext[1];

        axios
        .get(url, {responseType: 'blob'})
        .then(response => {
            saveAs(response.data, file);
        })
        
        //await ServicioFacturas.downloadFile(Archivo);
      },
      async buscarRFC() {
        let resRegistros;
        
        if(this.RFC.length >= 12){

          this.loading = true;

          const datosRFC = {
            RFC: this.RFC,
          }
          
          resRegistros = await ServicioFacturas.getRFC(datosRFC);
          if (resRegistros.data.data.Table0.length > 0){
            this.RazonSocial = resRegistros.data.data.Table0[0].RazonSocial,
            this.RegimenFiscalID = resRegistros.data.data.Table0[0].RegimenFiscalID,
            this.CodigoPostal = resRegistros.data.data.Table0[0].CodigoPostal,
            this.UsoCFDIID = resRegistros.data.data.Table0[0].UsoCFDIID,
            this.Email = resRegistros.data.data.Table0[0].Email
          }
        
          this.loading = false;

        }

      },
      async validarFactura(){
        this.loading = true;

        if (this.$refs.formDatos.validate()) {
          const datos = {
            DSucursalID: this.DSucursalID,
            Folio: this.Folio,
            Fecha: this.date,
            Importe: this.Importe
          }

          this.DesactivarBotonPaso1 = true;

          const resValidacion = await ServicioFacturas.validarTicket(datos);
          if (resValidacion.data.result === 1) {
            
            if (typeof resValidacion.data.data.Table0 !== 'undefined'){              
              this.paso = 2;
            
              let res = await ServicioFacturas.CatalogosCFDI();
              this.catalogos.RegimenFiscal = res.data.data.Table0;
              this.catalogos.UsoCFDI = res.data.data.Table1;
              this.catalogos.FormasPago = res.data.data.Table2;
            
            }else{
                
                if(resValidacion.data.output !== "OK") {
                    this.$root.mensajeError = resValidacion.data.output;
                }else{
                    this.$root.mensajeError = "La información ingresada no es válida";
                }
              
            }
            
          }else {
            this.$root.mensajeError = resValidacion.data.message || "Error al Validar Ticket.";
          }

          this.DesactivarBotonPaso1 = false;

        }
        
        this.loading = false;
      },
      async generarFactura() {
        this.DesactivarBotonPaso2 = true;
        this.loading = true;
        
        if (this.$refs.formDatos.validate()) {
          const datosFactura = {
            DSucursalID: this.DSucursalID,
            Folio: this.Folio,
            Fecha: this.date,
            Importe: this.Importe,
            RazonSocial: this.RazonSocial,
            RFC: this.RFC,
            CodigoPostal: this.CodigoPostal,
            RegimenFiscalID: this.RegimenFiscalID,
            UsoCFDIID: this.UsoCFDIID,
            FormaPagoID: this.FormaPagoID,
            Email: this.Email,
          }

          const resValidacion = await ServicioFacturas.generarFactura(datosFactura);
          if (resValidacion.data.result === 1) {
            if (typeof resValidacion.data.data.Table0 !== 'undefined'){
              
              this.paso = 3;

              this.URLPDF = resValidacion.data.data.Table0[0].PDF;
              this.URLXML = resValidacion.data.data.Table0[0].XML;

              this.$root.mensaje = "Factura Generada";

            }else{
              //this.$root.mensajeExito = "Se ejecutó correctamente la petición al servidor";
              this.$root.mensajeError = resValidacion.data.message || "La información ingresada no es válida";
            }
            
          }else {
            this.$root.mensajeError = resValidacion.data.message || "Error al generar la factura.";
          }

        }
        this.loading = false;
        this.DesactivarBotonPaso2 = false;
      },
      pasoAnterior(){
        this.paso = 1;
      }
    },
    async mounted() {
      this.loading = true;
      
      //let res = await ServicioFacturas.Catalogos();
      let res = await ServicioFacturas.CatalogosSucursales();
      this.catalogos.Sucursales = res.data.data.Table0;
      //this.catalogos.RegimenFiscal = res.data.data.Table1;
      //this.catalogos.UsoCFDI = res.data.data.Table2;
      //this.catalogos.FormasPago = res.data.data.Table3;
      this.loading = false;
    }
  }
</script>
