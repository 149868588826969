<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
          <v-icon large>mdi-robot</v-icon>
          <span class="mr-2 ml-2">Morro Facturación</span>
      </div>

      <v-spacer></v-spacer>

     
        <span class="mr-2 text-caption font-weight-light">Versión 1.2</span>
     
    </v-app-bar>
    
    <v-main>
      <FacturacionMorro/>
    </v-main>
  
    <v-snackbar
      class="ma-2"
      multi-line
      color="error"
      :timeout="5000"
      right
      :top="true"
      v-if="$root.mensajeError"
      v-model="$root.mensajeError"
    >
      <!--<v-icon color="white" large>info</v-icon>-->
      {{ $root.mensajeError }}
      <v-btn color="white" text v-on:click.native="$root.mensajeError = null"
        >Cerrar</v-btn
      >
    </v-snackbar>
    <v-snackbar
      class="ma-2"
      multi-line
      color="success"
      :timeout="5000"
      right
      :top="true"
      v-if="$root.mensajeExito"
      v-model="$root.mensajeExito"
    >
      <!--<v-icon color="white" large>info</v-icon>-->
      {{ $root.mensajeExito }}
      <v-btn color="white" text v-on:click.native="$root.mensajeExito = null"
        >Cerrar</v-btn
      >
    </v-snackbar>
    <ConfirmarMensaje></ConfirmarMensaje>

  </v-app>



</template>

<script>


import FacturacionMorro from './components/Facturacion';
import ConfirmarMensaje from './components/Confirmar';

export default {
  name: 'App',

  components: {
    FacturacionMorro, ConfirmarMensaje
  },

  data: () => ({
   
  }),
};
</script>
