import axios from 'axios';
import config from '../config';
//import router from '../router';

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';
/*axios.defaults.headers.post['Accept'] =
  'application/json, text/javascript/ *.*;';*/

const _axios = axios.create({});
const apiPath = config.RutaApi;

var pendingRequests = 0;
_axios.interceptors.request.use(
  function(config) {
    pendingRequests++;
    setTimeout(function() {
      if (window.$Vm && pendingRequests > 0) window.$Vm.$root.isLoading = true;
    }, 500);
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    if (response.status === 401) {
      //router.replace('/Login');
    }
    const url = response.config.url.toLowerCase();
    if (url.indexOf('catalogos/') > -1) {
      const parts = url.split('/');
      const cat = parts[parts.length - 1];
      if (response.data.result === 1) {
        localStorage.setItem(`cat_${cat}`, JSON.stringify(response));
      }
    }
    pendingRequests--;
    if (pendingRequests == 0) {
      if (window.$Vm) window.$Vm.$root.isLoading = false;
    }
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      //router.replace('/Login');
    }
    pendingRequests--;
    if (pendingRequests == 0) {
      if (window.$Vm) window.$Vm.$root.isLoading = false;
    }
    return Promise.reject(error);
  }
);

export function apiPost(metodo, params) {
  var fullPath;
  fullPath = apiPath + metodo;

  return _axios.post(
    fullPath,
    {...params, modulo: 'digitrack', Modulo: 'digitrack'},
    {
      headers: {
        Authorization: localStorage.getItem('jwt')
          ? `Bearer ${localStorage.getItem('jwt')}`
          : '',
      },
    }
  );
}

export function apiPostUpload(metodo, archivo) {
  var fullPath;
  fullPath = apiPath + metodo;

  var formData = new FormData();
  formData.append('file', archivo);

  return _axios.post(fullPath, formData, {
    headers: {
      Authorization: localStorage.getItem('jwt')
        ? `Bearer ${localStorage.getItem('jwt')}`
        : '',
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function apiPostUploadImagen(metodo, blob) {
  var fullPath;
  fullPath = apiPath + metodo;

  var formData = new FormData();
  formData.append('image', blob);

  return _axios.post(fullPath, formData, {
    headers: {
      Authorization: localStorage.getItem('jwt')
        ? `Bearer ${localStorage.getItem('jwt')}`
        : '',
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function apiGet(metodo, params) {
  var fullPath;
  fullPath = apiPath + metodo;

  return _axios.get(fullPath, 
    {...params, modulo: 'digitrack', Modulo: 'digitrack'},
    {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  });
}


